import React from "react";
import "./joinus.css";
const JoinUs = () => {
  return (
    <section className="flex flex-row h-screen xs:h-auto xs:min-h-screen] w-full">
      <aside className="h-full flex flex-col justify-center xs:hidden ">
        <img src="/asset/join-us.svg" alt="JoinUs" />
      </aside>
      <div
        className="flex flex-col justify-center items-start gap-[10px]
        w-1/2 lg:pl-[50px] sm:w-full xs:w-full xs:justify-start xs:pt-7 pl-7 
        xs:px-[15px] xs:grow"
      >
        <h2 className="title py-[10px]">Únete</h2>
        <p className="flex flex-col justify-center items-left gap-[12px] description ">
          <span>¿Eres un apasionado de la tecnología? </span>
          <span>¿Quieres trabajar en proyectos innovadores y desafiantes?</span>
          <span>
            ¿Estás buscando un equipo joven y dinámico donde puedas crecer
            profesionalmente?
          </span>
        </p>
        <p className="text">
          Si has respondido sí a estas preguntas, ¡Tech-House es tu lugar!
        </p>
        <p className="text sm:hidden">
          Supérate, desarrolla tu potencial, trabaja en proyectos apasionantes,
          únete a un equipo dinámico y multicultural, aprende de los mejores.
          ¿Qué esperas?{" "}
        </p>
        <a 
          className="
            lg:text-h3_lg md:text-h3_md sm:text-h3_sm xs:text-h3_xs pt-[20px]"
          href="mailto:business@tech-house.tech?subject=Hola, TechHouse"
        >
          Envíanos tu CV y únete a la revolución tecnológica
        </a>
        <div className="h-auto w-full justify-center items-center hidden xs:flex pt-[24px] ">
          <img
            src="/asset/join-us.svg"
            alt="JoinUs"
            className=""
            style={{ maxHeight: "200px", width: "200px" }}
          />
        </div>
      </div>
    </section>
  );
};

export default JoinUs;
