import { v4 as uuidv4 } from 'uuid';

class ImgSlider {
  constructor(url, name, rol) {
    this.id = uuidv4();
    this.url = url;
    this.name = name;
    this.rol = rol;
  }
}

export let imgSlider = [
  new ImgSlider("/asset/slider/amelia_pereyra.png", "Amelia", "QA Specialist"),
  new ImgSlider("/asset/slider/carlos_lopez.png", "Carlos", "Backend Developer"),
  new ImgSlider("/asset/slider/mariana_lamouret.png", "Marian", "Client Partner"),
  new ImgSlider("/asset/slider/dario_rocha.png", "Dario", "Backend Developer"),
  new ImgSlider("/asset/slider/agustin_estrada.png", "Agustín", "Full Stack Developer"),
  new ImgSlider("/asset/slider/paz_lares.png", "Paz", "Internship"),
  new ImgSlider("/asset/slider/gonzalo_quarin.png", "Gonzalo", "Technical Lead"),
  new ImgSlider("/asset/slider/german_sanchez.png", "German", "DevOps Engineer"),
  new ImgSlider("/asset/slider/martin_andres.png", "Martín", "Backend Developer"),
  new ImgSlider("/asset/slider/matias_casoli.png", "Matías", "Technical Lead"),
  new ImgSlider("/asset/slider/sofia_mondino.png", "Sofia", "Internship"),
];
