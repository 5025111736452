import React from "react";
import Slider from "../../components/slider/Slider";
import "./team.css";
const Team = () => {
  return (
    <section className="flex flex-col justify-center items-center h-screen w-full xs:h-auto xs:min-h-screen">
      <div className="flex flex-col justify-center w-full xs:grow">
        <p className="text-left title-team text-black xs:px-[15px]">
          Nuestro equipo
        </p>
        <h2 className="title-2-team text-[#282C31]">Nuestro dream team</h2>
        <Slider />
      </div>
    </section>
  );
};

export default Team;
