import React from "react";
import "./about.css";
const About = () => {
  return (
    <section className="flex flex-row h-screen xs:h-auto xs:min-h-screen w-full  ">
      <div
        className="flex flex-col xs:grow justify-center items-start w-1/2 lg:pr-[50px] 
                      sm:w-full xs:w-full xs:justify-start xs:pt-7 xs:px-[15px]"
      >
        <h2 className="title">Quiénes Somos</h2>
        <p className="subtitle">
          Somos un equipo de profesionales apasionados por los desafíos y las
          nuevas tecnologías. Impulsamos negocios con soluciones de alto nivel,
          colaborando e innovando de acuerdo a los proyectos y estando a la
          vanguardia del mercado digital. ¡Hacemos realidad tus expectativas!
        </p>

        <p className="subtitle xs:mt-[30px]">
          Nuestra cultura es clara y concisa:
        </p>
        <p className="font-bold lg:text-h2_lg md:text-h2_md sm:text-h2_sm xs:text-h2_xs py-[20px] xs:py-[5px]">
          ¡Las personas son nuestra prioridad!
        </p>
        <p className="subtitle" style={{ textAlign: "left" }}>
          Equipos felices y talentosos impulsan mejores soluciones, y es por eso
          construimos un espacio abierto a nuevas ideas y al debate, mientras
          que potenciamos la colaboración, la creatividad y la flexibilidad.
          <br />
          Queremos que nuestra gente se desarrolle tanto profesional como
          personalmente.
        </p>
        <p className="subtitle xs:hidden" style={{ textAlign: "left" }}>
          Cada persona es única y agrega valor, por eso estamos enfocados en
          reunir a las mejores personas y crear un dream team para cada
          proyecto.
        </p>
        <div className="w-full h-auto flex justify-center items-end mt-6">
          <img
            src="/asset/about-1-.svg"
            alt="About"
            className="hidden xs:flex"
            style={{ maxHeight: "200px", width: "200px" }}
          />
        </div>
      </div>
      <aside className="h-full  flex flex-col justify-center xs:hidden ">
        <img src="/asset/about-1-.svg" alt="About" className="" />
      </aside>
    </section>
  );
};

export default About;
