import React from 'react'
const CardWithImage = ({img, title, description}) => {
  return (
    <div className='h-3/5 lg:h-4/5 w-1/3 flex flex-col justify-start items-center p-5 md:p-3 sm:p-2 xs:w-full xs:border-none media-query' 
    style={{}}
    >
        <div className='min-h-[80px] min-w-[80px] md:min-h-[50px] md:min-w-[50px] sm:min-h-[40px] sm:min-w-[40px] xs:min-h-[40px] xs:min-w-[40px]
        flex justify-center items-center rounded-full bg-[#FF4265]'>
            <img src={img} alt={img} className='h-[32px] w-[32px] md:h-[22px] md:w-[22px] sm:h-[18px] sm:w-[18px] xs:h-[22px] xs:w-[22px] '/>
        </div>
        <p className='font-bold pt-3 md:pt-2 text-h3_sm md:text-h3_xs sm:text-h3_xs text-center'>{title}</p>
        <p className='text-center pt-2 md:pt-1 md:text-[12px] sm:text-[12px]'>{description}</p>
    </div>
  )
}

export default CardWithImage