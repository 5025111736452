const Layout = ({ children, addClass }) => {
  return (
    <div
      className={`flex flex-col justify-center items-center h-auto w-full ${addClass}`}
    >
      <section
        id="app-section"
        className={`flex flex-col justify-center items-center h-auto w-full
                  lg:w-[1296px]
                  md:w-[920px]
                  sm:w-[690px]
                  xs:w-[375px] xs:min-w-[375px]
                  `}
      >
        {children}
      </section>
    </div>
  );
};

export default Layout;
