import React from "react";

const Card = ({ title, subtitle, img }) => {
  return (
    <div className="card rounded-t-[24px] overflow-clip">
      <div
        className="img-slider 
        lg:h-[350px] lg:w-[270px]
        md:h-[300px] md:w-[220px]
        sm:h-[250px] sm:w-[170px]
        xs:h-[350px] xs:w-[230px]"
        style={{ backgroundImage: `url(${img})` }}
      ></div>
      <h2 className="font-bold xs:text-h1_xs pt-[20px]">{title}</h2>
      <p className="xs:text-h3_xs">{subtitle}</p>
    </div>
  );
};

export default Card;
