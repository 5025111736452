import React from "react";
import useWindowSize from "../../components/hooks/useWindowsSize";
import "./contact.css";

const Contact = () => {
  const { is_mobile } = useWindowSize();
  const redirectToURL = (url) => {
    window.open(url, "_blank");
  };

  const _scrollToOption = ({ index }) => {
    console.log(index);
    const moreScrollMobile =
      is_mobile && index == 4.15
        ? 2.5
        : index == 3.15
          ? 0.4
          : index == 2.15
            ? 0
            : index == 1.2
              ? -0.5
              : 0;
    window.scrollBy({
      top: -window.innerHeight * (index + 0.4 + moreScrollMobile), // Cambio de signo para desplazamiento hacia arriba
      behavior: "smooth",
    });
  };

  const _getTitle = (title) => {
    return (
      <span
        className="text-[#f8c201]  font-bold
                  lg:text-h3_md
                  md:text-h2_sm
                  sm:text-h2_sm
                  xs:text-h2_xs"
      >
        {title}
      </span>
    );
  };

  return (
    <footer
      className="h-auto w-full flex flex-col bg-[#282C31] justify-center items-center
                 px-[0px] gap-5
                 xs:h-auto xs:flex-col"
    >
      <div
        className="h-auto w-full flex flex-row justify-between items-start
                   xs:h-full xs:grow xs:flex-col"
      >
        <div
          className="h-full w-auto min-w-32 flex flex-col justify-start items-start p-7 gap-5
                  text-white text-center
                    xs:h-auto xs:items-center xs:w-full"
        >
          {_getTitle("Nuestro sitio")}

          <button onClick={() => _scrollToOption({ index: 4.15 })}>
            Qué hacemos
          </button>
          <button onClick={() => _scrollToOption({ index: 3.15 })}>
            Quiénes somos
          </button>
          <button onClick={() => _scrollToOption({ index: 2.15 })}>
            Únete
          </button>
          <button onClick={() => _scrollToOption({ index: 1.2 })}>
            Nuestro equipo
          </button>
        </div>

        <div
          className="h-full w-auto min-w-32 flex flex-col justify-start items-start p-7 gap-5
                   text-white text-center 
                     xs:h-auto xs:w-full xs:items-center"
        >
          {_getTitle("Contáctanos")}

          <a href="mailto:business@tech-house.tech?subject=Hola, TechHouse">
            📧 Business@tech-house.tech
          </a>
          <a
            className="link"
            href="mailto:business@tech-house.tech?subject=Hola, TechHouse"
          >
            ¡Hablemos!
          </a>
        </div>

        <div
          className="h-full w-auto min-w-32 flex flex-col justify-start items-start p-7 gap-5
                   text-white text-center
                     xs:h-auto xs:w-full xs:items-center"
        >
          {_getTitle("Dónde estamos")}

          <p>📍 2201 MENAUL BLVD NE STE A</p>
          <p>Albuquerque NM 87107- USA</p>
        </div>
      </div>

      <div
        className="h-auto w-full flex flex-row justify-between p-6
                   xs:flex-col xs:gap-[30px]  xs:items-center"
      >
        <div className="h-full w-2/4 xs:flex-row flex flex-row items-center justify-start gap-6 ">
          <img
            className="cursor-pointer"
            src="/asset/linkedin.svg"
            alt="linkedin"
            style={{ height: "40px", width: "40px" }}
            onClick={() =>
              redirectToURL("https://www.linkedin.com/company/tech-house-tech/")
            }
          />
          <img
            className="cursor-pointer"
            src="/asset/spotify.svg"
            alt="spotify"
            style={{ height: "40px", width: "40px" }}
            onClick={() =>
              redirectToURL(
                "https://open.spotify.com/playlist/6AUKnfykcMxxrdYSbOX792?si=3JJbbi80SnC3Tt68fXQV_A&pi=e-fVH4MErsTsi7"
              )
            }
          />
          <img
            className="cursor-pointer"
            src="/asset/whatsapp.svg"
            alt="whatsapp"
            style={{ height: "40px", width: "40px" }}
            onClick={() => redirectToURL("https://wa.me/51959375623")}
          />
        </div>
        <img
          src="/asset/logo.svg"
          alt="logo"
          className="flex h-auto w-auto"
          style={{ width: "200px" }}
        />
      </div>
    </footer>
  );
};

export default Contact;
