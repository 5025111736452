import { useState, useEffect } from "react";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        is_mobile: window.innerWidth < 767,
        text_size: () => {
          switch (true) {
            case window.innerWidth < 767:
              return "xs";
            case window.innerWidth < 1024:
              return "sm";
            case window.innerWidth < 1440:
              return "md";
            default:
              return "lg";
          }
        },
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    // Llama al manejador inmediatamente para que el estado se inicialice con el tamaño actual
    handleResize();

    // Elimina el escuchador de eventos cuando el componente se desmonta
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Los corchetes vacíos aseguran que el efecto se ejecute una sola vez

  return windowSize;
}

export default useWindowSize;
