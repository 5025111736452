import React from "react";

const Arrow = ({ imageClass, addClass, handleClick }) => {
  return (
    <div
      className={`flex justify-center items-center h-auto w-auto ${addClass}`}
    >
      <div className="icon-arrow" onClick={handleClick}>
        <div className={`${imageClass}`}></div>
      </div>
    </div>
  );
};

export default Arrow;
