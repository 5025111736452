import React, { useState } from "react";
import Arrow from "./Arrow";
import Card from "./Card";
import useWindowSize from "../hooks/useWindowsSize";
import { imgSlider } from "./img/exporter";

const Slider = () => {
  const { is_mobile } = useWindowSize();
  const [array, setArray] = useState(imgSlider);
  const [bubbleIndex, setBubbleIndex] = useState(0);

  

  function _rotateArrayRight() {
    setBubbleIndex(bubbleIndex == 0 ? 1 : 0);

    var _array = [...array];
    if (_array.length <= 1) {
      return;
    }

    const lastElement = _array.pop();
    _array.unshift(lastElement);

    setArray(_array);
  }

  function _rotateArrayLeft() {
    setBubbleIndex(bubbleIndex == 0 ? 1 : 0);
    var _array = [...array];

    if (_array.length <= 1) {
      return;
    }

    const firstElement = _array.shift();
    _array.push(firstElement);

    setArray(_array);
  }

  const _slider = ({ pageSize }) => {
    return (
     
      <div className="flex box-item-slider mt-[30px] gap-[50px] xs:grow">
        {array.slice(0, pageSize).map((item) => (
          <Card
            key={item.id}
            title={item.name}
            subtitle={item.rol}
            img={item.url}
          />
        ))}
      </div>
    );
  };

  const _pageSize = {
    lg: 3,
    md: 3,
    sm: 2,
    xs: 1,
  };

  return (
    <div className="flex flex-col justify-center items-center  h-auto w-full gap-[32px]">
     {array.map((e) => (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img src={e} style={{ display: "none" }} />
  ))}
    
      <div className="flex flex-row h-auto justify-center items-center">
        <Arrow
          imageClass={"arrow-left"}
          addClass={"p-[20px] xs:p-[0px] xs:pr-[10px]"}
          handleClick={_rotateArrayRight}
        />

        {_slider({ pageSize: is_mobile ? _pageSize.xs : _pageSize.lg })}
        <div className="flex box-points mt-[30px] xs:hidden">
        
        
      </div>

        <Arrow
          imageClass={"arrow-right"}
          addClass={"size-[50px] p-[20px] xs:p-[0px] xs:pl-[10px]"}
          handleClick={_rotateArrayLeft}
        />
      </div>
      <div className="flex flex-row justify-center items-center xs:hidden">
      <div
          className="point"
          style={{
            backgroundColor: bubbleIndex == 0 ? "black" : "white",
          }}
        ></div>

        <div
          className="point"
          style={{
            backgroundColor: bubbleIndex == 1 ? "black" : "white",
          }}
        ></div>
      </div>
      
    </div>
  );
};

export default Slider;
